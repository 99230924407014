import {useState, useEffect} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import { getActiveTables, getMinutes } from '../libs/helpers';
import TableContainer from '@mui/material/TableContainer';
import _ from 'lodash';
import { format } from 'date-fns/fp';
import { Box } from '@mui/material';

const ActiveTables = ({userId}) => {
  const [data, setData] = useState([]);
  
  useEffect(() => {
    loadActiveTables();
  }, [userId])

  const loadActiveTables = async () => {
    try{ 
      const response = await getActiveTables(userId);
      const extendedData =_.orderBy(response.map(d => ({
        ...d,
        minutes: getMinutes(d.startTime)
      })), 'id', 'asc')
      setData(extendedData);
    } catch (e) {
      console.log(e);
    }
  }




  return (
    <TableContainer>
      <Title>Aktivni stolovi</Title>
      <Table size="small" >
        <TableHead>
          <TableRow>
            <TableCell>Broj stola</TableCell>
            <TableCell>Početno vreme</TableCell>
            <TableCell>Cena</TableCell>
            <TableCell>Minuta</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.id}</TableCell>
              <TableCell>{format('dd.MM.yyyy. hh:mm a')(new Date(row.startTime))}</TableCell>
              <TableCell>{row.price}</TableCell>
              <TableCell>{row.minutes}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ActiveTables;