import * as urls from './urls'
import { toast } from 'react-toastify';

const getPostConfig = (data = {}) => {
  return {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...data
    }),
  };
}

export const loginRequest = async (userName, password) => {
  const config = getPostConfig({ userName, password});
  let response = await fetch(urls.billibosLoginUrl, config);
  let json = await response.json();
  if (response.status === 200) {
    return json;
  }
  toast.error(json.message)
  return null;
}

export const getActiveTables = async (userId) => {
  const config = getPostConfig({userId});
  let response = await fetch(urls.billibossGetActiveTableUrl, config);
  let json = await response.json();
  if (response.status === 200) {
    return json.data;
  }
  toast.error(json.message)
  return [];
};

export const getTotalRequest = async (gte, lte, userId) => {
  const config = getPostConfig({gte, lte, userId})

  let response = await fetch(urls.billibossTotalUrl, config);
  let json = await response.json();
  if (response.status === 200) {
    return json;
  }
  toast.error(json.message)
  return {
    total: '--',
    totalMinutes: '--'
  };
}

export const getStatsByTables = async (time, userId) => {
  const config = getPostConfig({time, userId})

  let response = await fetch(urls.billibossTableStats, config);
  let json = await response.json();
  if (response.status === 200) {
    return json.data;
  }
  toast.error(json.message)
  return [];
}

export const getMinutes = (startTime) => {
  const x = new Date(startTime);
  const y = new Date();
  const m = Math.floor(Math.abs(x.getTime() - y.getTime())/1000/60);
  return m;
}