import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BarChartIcon from '@mui/icons-material/BarChart';
import { BilliBossPages } from '../libs/enums';


const drawerWidth = 240;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ open }) => ({
    '& .MuiDrawer-paper': {
      position: 'absolute',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      top: 0,
      bottom: 0,
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        display: 'none'
      }),
    },
  }),
);


function Sidebar({isSidebarOpen, setSelectedPage, selectedPage, setIsSidebarOpen}) {

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer variant="permanent" open={isSidebarOpen}>

        <List component="nav">
          <ListItemButton selected={selectedPage === BilliBossPages.tables } onClick={() => {
              setSelectedPage(BilliBossPages.tables)
              setIsSidebarOpen(false);
            }}>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Aktivni stolovi" />
          </ListItemButton>
          <ListItemButton selected={selectedPage === BilliBossPages.stats } onClick={() => {
              setSelectedPage(BilliBossPages.stats)
              setIsSidebarOpen(false);
            }}>
            <ListItemIcon>
              <BarChartIcon />
            </ListItemIcon>
            <ListItemText primary="Statistika" />
          </ListItemButton>
        </List>
      </Drawer>
    </Box>
  );
}

export default Sidebar;