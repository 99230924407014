import { useState } from 'react';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import ActiveTables from '../../components/ActiveTables';
import LogoutIcon from '@mui/icons-material/Logout';
import { IconButton } from '@mui/material';
import SignIn from "../SignIn";
import Sidebar from "../../components/Sidebar";
import backgroundImg from '../../images/background.jpg';
import MenuIcon from '@mui/icons-material/Menu';
import { BilliBossPages } from '../../libs/enums';
import StatisticPage from './StatisticPage'



const MainScreen = () => {
  const [user, setUser] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [selectedPage, setSelectedPage] = useState(BilliBossPages.tables);
  const logout = () => {
    setUser(null);
  }
  return (
    <Box sx={{ display: 'flex' }}>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
        >
        {!!user ? (
          <>
            <Sidebar isSidebarOpen={isSidebarOpen} setSelectedPage={setSelectedPage} setIsSidebarOpen={setIsSidebarOpen} selectedPage={selectedPage} />
            <div style={{marginLeft: isSidebarOpen ? 240: 0}}>
              <>
                <CssBaseline />
                <Box sx={{ display: 'flex' }}>
                  <Box
                    component="main"
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                          ? theme.palette.grey[100]
                          : theme.palette.grey[900],
                      flexGrow: 1,
                      height: '100vh',
                      overflow: 'auto',
                    }}
                  >
                    <MuiAppBar position="relateive" >
                      <Toolbar>
                        <IconButton
                          edge="start"
                          color="inherit"
                          aria-label="open drawer"
                          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                          sx={{
                            marginRight: '20px'
                          }}
                        >
                          <MenuIcon />
                        </IconButton>
                        <Typography
                          component="h1"
                          variant="h6"
                          color="inherit"
                          noWrap
                          sx={{ flexGrow: 1 }}
                        >
                          BilliBoss - {user.company}
                        </Typography>
                        <IconButton color="inherit" onClick={logout}>
                          <LogoutIcon />
                        </IconButton>
                      </Toolbar>
                    </MuiAppBar>
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                            { selectedPage === BilliBossPages.tables && (
                              <ActiveTables userId={user.id} />
                            )}
                            { selectedPage === BilliBossPages.stats && (
                              <StatisticPage userId={user.id} />
                            )}
                          </Paper>
                        </Grid>
                      </Grid>
                    </Container>
                  </Box>
                </Box>
              </>
            </div>
          </>
        ) : (
          <div>
            <SignIn setUser={setUser} />
          </div>
        )
        }
      </Box>
    </Box>
  );
}

export default MainScreen;