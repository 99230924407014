import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NotFound from "./views/NotFound";
import { Paths } from './libs/enums'
import { BilliBossMain } from "./views/billiboss";
import { MainPage } from './views';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';


function App() {
  return (
    <>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover />
      <Router>
        <Routes>
          <Route path={Paths.mainScreen} element={<MainPage/>} />
          <Route path={Paths.biliboss} element={<BilliBossMain/>} />
          <Route path='*' element={<NotFound />}/>
        </Routes>
      </Router>

    </LocalizationProvider>
    </>
  );
}

export default App;
