import { Link } from "react-router-dom";
import { Paths } from "../libs/enums";
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import backgroundImg from '../images/background.jpg'
import billiBossImg from '../images/billiboss.png'
import Copyright from "../components/Copyright";

export default function NotFound() {
  return (
      <Box sx={{ display: 'flex' }}>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            backgroundImage: `url(${backgroundImg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: 'cover',
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Typography color='white' textAlign={'center'} fontSize={32} sx={{
              my: 10
            }}>VR SKYNET</Typography>
            <Grid container   direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={3}>
              <Grid item xl={4} md={12} sx={{ ":hover": {
                  backgroundColor: 'rgba(0,0,0,0.2)'
                  }}} >
                <Link 
                  className="mainPageIcon" to={Paths.biliboss}>
                  <Typography color='white'>BilliBoss</Typography>
                  <img alt='' src={billiBossImg} width={300}/>
                </Link>
              </Grid>
            </Grid>
          </Container>
          <Copyright sx={{ mt: 8, mb: 4, position: 'absolute', bottom: 0, right: 20,  color: 'white' }} />
        </Box>
      </Box>
  )  
}